import React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import ReactMarkdown from "react-markdown"
import SEO from "../components/seo"
import { DiscussionEmbed } from "disqus-react"
import Share from "../components/share"

const ArticleTemplate = ({ data, pageContext }) => {
  const disqusConfig = {
    shortname: process.env.GATSBY_DISQUS_NAME,
    config: { identifier: data.strapiArticle.slug },
  }
  return (
    <Layout>
      <SEO
        title={data.strapiArticle.title}
        description="Lese Joker Buch Blog Yay"
      />
      <div className="container mx-auto px-4">
        <h1 className="w-full font-light text-3xl text-center text-joker dark:text-gray-200 italic mt-3">
          {data.strapiArticle.title}
        </h1>
        <p className="text-gray-600 dark:text-gray-200 underline text-right">
          {data.strapiArticle.date}
        </p>
        <p className="text-gray-800 dark:text-gray-200 font-sans text-justify mb-5 mt-2 leading-relaxed">
          <ReactMarkdown
            source={data.strapiArticle.content}
            transformImageUri={uri =>
              uri.startsWith("http")
                ? uri
                : `${process.env.IMAGE_BASE_URL}${uri}`
            }
          />
        </p>
        <hr />
        <Share
          title={data.strapiArticle.title}
          slug={`/${data.strapiArticle.slug}`}
          excerpt={data.strapiArticle.content}
        />
        <hr />

        <DiscussionEmbed {...disqusConfig} className="mt-3" />
      </div>
    </Layout>
  )
}
export default ArticleTemplate

export const query = graphql`
  query ArticleTemplate($slug: String!) {
    strapiArticle(slug: { eq: $slug }) {
      id
      title
      content
      slug
      date(formatString: "DD-MM-Y")
      image {
        childImageSharp {
          fluid(maxWidth: 960) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  }
`
