module.exports = {
    siteTitle: 'Lese Joker Buch Rezension Website', // Site title.
    siteTitleAlt: 'Bücher Rezension', // Alternative site title for SEO.
    siteLogo: '/logo/logopng.png', // Logo used for SEO and manifest.
    siteUrl: 'https://lesejoker.de', // Domain of your website without pathPrefix.
    pathPrefix: '', // Prefixes all links. For cases when deployed to example.github.io/gatsby-starter-business/.
    siteDescription: 'Lese Joker Bücher Rezension website.', // Website description used for RSS feeds/meta description tag.
    siteRss: '/rss.xml',
    siteFBAppID: '', // FB Application ID for using app insights
    googleTagManagerID: '', // GTM tracking ID.
    userName: 'Lese Joker',
    userTwitter: 'lesejoker',
    userLocation: 'Frankfurt, Germany',
    userDescription: '',
    copyright: 'Copyright © Lese Joker 2020. All Rights Reserved.', // Copyright string for the footer of the website and RSS feed.
    themeColor: '#00d1b2', // Used for setting manifest and progress theme colors.
    backgroundColor: '#ffffff', // Used for setting manifest background color.
  }
  